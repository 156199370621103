*, ::before, ::after{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

:root {
    --tw-scroll-snap-strictness: mandatory;
  }

*{
    font-family: Manrope, sans-serif;
}

html,
  body {
    min-height: 100%;
  }

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    flex: 1 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: auto;
    font-family: Manrope, sans-serif;
  }

#root {
    height: 100%;
  }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
.container{
    width: 100%;
}
.\!container{
    width: 100% !important;
}
@media (min-width: 640px){

    .container{
        max-width: 640px;
    }

    .\!container{
        max-width: 640px !important;
    }
}
@media (min-width: 768px){

    .container{
        max-width: 768px;
    }

    .\!container{
        max-width: 768px !important;
    }
}
@media (min-width: 1024px){

    .container{
        max-width: 1024px;
    }

    .\!container{
        max-width: 1024px !important;
    }
}
@media (min-width: 1280px){

    .container{
        max-width: 1280px;
    }

    .\!container{
        max-width: 1280px !important;
    }
}
@media (min-width: 1536px){

    .container{
        max-width: 1536px;
    }

    .\!container{
        max-width: 1536px !important;
    }
}
dialog::-webkit-backdrop{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-500) / var(--twc-neutral-500-opacity, var(--tw-bg-opacity)));
    --tw-bg-opacity: 0.6;
}
dialog::backdrop{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-500) / var(--twc-neutral-500-opacity, var(--tw-bg-opacity)));
    --tw-bg-opacity: 0.6;
}
/* to prevent background scroll and weird positioning when dialog is open */
html:has(dialog[open]:modal){
    overflow: hidden;
}
.sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.pointer-events-none{
    pointer-events: none;
}
.pointer-events-auto{
    pointer-events: auto;
}
.visible{
    visibility: visible;
}
.static{
    position: static;
}
.fixed{
    position: fixed;
}
.absolute{
    position: absolute;
}
.relative{
    position: relative;
}
.inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.inset-y-0{
    top: 0px;
    bottom: 0px;
}
.top-0{
    top: 0px;
}
.right-0{
    right: 0px;
}
.left-0{
    left: 0px;
}
.bottom-0{
    bottom: 0px;
}
.top-1{
    top: 0.25rem;
}
.right-0\.5{
    right: 0.125rem;
}
.top-4{
    top: 1rem;
}
.right-4{
    right: 1rem;
}
.-top-10{
    top: -2.5rem;
}
.right-\[24\%\]{
    right: 24%;
}
.left-5{
    left: 1.25rem;
}
.left-3{
    left: 0.75rem;
}
.right-3{
    right: 0.75rem;
}
.left-\[2px\]{
    left: 2px;
}
.z-\[99\]{
    z-index: 99;
}
.z-\[90\]{
    z-index: 90;
}
.z-50{
    z-index: 50;
}
.z-10{
    z-index: 10;
}
.z-20{
    z-index: 20;
}
.z-\[100\]{
    z-index: 100;
}
.order-last{
    order: 9999;
}
.col-span-2{
    grid-column: span 2 / span 2;
}
.float-right{
    float: right;
}
.m-1{
    margin: 0.25rem;
}
.m-0{
    margin: 0px;
}
.m-2{
    margin: 0.5rem;
}
.mx-auto{
    margin-left: auto;
    margin-right: auto;
}
.my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
.my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.my-8{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
.mx-\[\.25em\]{
    margin-left: .25em;
    margin-right: .25em;
}
.mt-2{
    margin-top: 0.5rem;
}
.mr-4{
    margin-right: 1rem;
}
.mt-1{
    margin-top: 0.25rem;
}
.mt-0{
    margin-top: 0px;
}
.mb-4{
    margin-bottom: 1rem;
}
.ml-4{
    margin-left: 1rem;
}
.mt-auto{
    margin-top: auto;
}
.mt-8{
    margin-top: 2rem;
}
.mt-6{
    margin-top: 1.5rem;
}
.mb-5{
    margin-bottom: 1.25rem;
}
.ml-2{
    margin-left: 0.5rem;
}
.-mt-3{
    margin-top: -0.75rem;
}
.-ml-8{
    margin-left: -2rem;
}
.-mr-8{
    margin-right: -2rem;
}
.mb-3{
    margin-bottom: 0.75rem;
}
.mt-5{
    margin-top: 1.25rem;
}
.mr-2{
    margin-right: 0.5rem;
}
.mr-7{
    margin-right: 1.75rem;
}
.mt-4{
    margin-top: 1rem;
}
.mb-2{
    margin-bottom: 0.5rem;
}
.ml-0{
    margin-left: 0px;
}
.-mt-1{
    margin-top: -0.25rem;
}
.mb-6{
    margin-bottom: 1.5rem;
}
.mb-10{
    margin-bottom: 2.5rem;
}
.mr-5{
    margin-right: 1.25rem;
}
.mb-0{
    margin-bottom: 0px;
}
.ml-5{
    margin-left: 1.25rem;
}
.mt-2\.5{
    margin-top: 0.625rem;
}
.mt-3{
    margin-top: 0.75rem;
}
.mt-9{
    margin-top: 2.25rem;
}
.mb-1{
    margin-bottom: 0.25rem;
}
.mt-12{
    margin-top: 3rem;
}
.mt-10{
    margin-top: 2.5rem;
}
.mb-8{
    margin-bottom: 2rem;
}
.ml-3{
    margin-left: 0.75rem;
}
.mt-7{
    margin-top: 1.75rem;
}
.mt-\[15px\]{
    margin-top: 15px;
}
.mr-\[100px\]{
    margin-right: 100px;
}
.box-border{
    box-sizing: border-box;
}
.block{
    display: block;
}
.inline-block{
    display: inline-block;
}
.inline{
    display: inline;
}
.flex{
    display: flex;
}
.inline-flex{
    display: inline-flex;
}
.table{
    display: table;
}
.grid{
    display: grid;
}
.inline-grid{
    display: inline-grid;
}
.hidden{
    display: none;
}
.h-14{
    height: 3.5rem;
}
.h-6{
    height: 1.5rem;
}
.h-7{
    height: 1.75rem;
}
.h-full{
    height: 100%;
}
.h-\[70px\]{
    height: 70px;
}
.h-20{
    height: 5rem;
}
.h-10{
    height: 2.5rem;
}
.h-5{
    height: 1.25rem;
}
.h-\[34px\]{
    height: 34px;
}
.h-11{
    height: 2.75rem;
}
.h-\[71px\]{
    height: 71px;
}
.h-\[27px\]{
    height: 27px;
}
.h-auto{
    height: auto;
}
.h-16{
    height: 4rem;
}
.h-8{
    height: 2rem;
}
.h-\[96px\]{
    height: 96px;
}
.h-0\.5{
    height: 0.125rem;
}
.h-0{
    height: 0px;
}
.h-\[35vh\]{
    height: 35vh;
}
.h-12{
    height: 3rem;
}
.h-\[1em\]{
    height: 1em;
}
.h-\[2\.5rem\]{
    height: 2.5rem;
}
.h-4{
    height: 1rem;
}
.h-\[45px\]{
    height: 45px;
}
.max-h-11{
    max-height: 2.75rem;
}
.max-h-full{
    max-height: 100%;
}
.max-h-72{
    max-height: 18rem;
}
.max-h-\[calc\(100vh-200px\)\]{
    max-height: calc(100vh - 200px);
}
.max-h-\[317px\]{
    max-height: 317px;
}
.max-h-\[169px\]{
    max-height: 169px;
}
.max-h-\[148px\]{
    max-height: 148px;
}
.max-h-96{
    max-height: 24rem;
}
.max-h-\[133px\]{
    max-height: 133px;
}
.max-h-\[500px\]{
    max-height: 500px;
}
.max-h-\[350px\]{
    max-height: 350px;
}
.max-h-10{
    max-height: 2.5rem;
}
.min-h-screen{
    min-height: 100vh;
}
.min-h-\[133px\]{
    min-height: 133px;
}
.min-h-dvh{
    min-height: 100dvh;
}
.min-h-\[30px\]{
    min-height: 30px;
}
.w-full{
    width: 100%;
}
.w-auto{
    width: auto;
}
.w-36{
    width: 9rem;
}
.w-5{
    width: 1.25rem;
}
.w-32{
    width: 8rem;
}
.w-6{
    width: 1.5rem;
}
.w-7{
    width: 1.75rem;
}
.w-screen{
    width: 100vw;
}
.w-\[80\%\]{
    width: 80%;
}
.w-\[20\%\]{
    width: 20%;
}
.w-20{
    width: 5rem;
}
.w-\[260px\]{
    width: 260px;
}
.w-\[270px\]{
    width: 270px;
}
.w-10{
    width: 2.5rem;
}
.w-\[34px\]{
    width: 34px;
}
.w-\[350px\]{
    width: 350px;
}
.w-\[90\%\]{
    width: 90%;
}
.w-11{
    width: 2.75rem;
}
.w-8{
    width: 2rem;
}
.w-9{
    width: 2.25rem;
}
.w-72{
    width: 18rem;
}
.w-\[75\%\]{
    width: 75%;
}
.w-\[98\%\]{
    width: 98%;
}
.w-5\/6{
    width: 83.333333%;
}
.w-14{
    width: 3.5rem;
}
.w-\[70\%\]{
    width: 70%;
}
.w-\[94\%\]{
    width: 94%;
}
.w-\[96px\]{
    width: 96px;
}
.w-1\/2{
    width: 50%;
}
.w-24{
    width: 6rem;
}
.w-max{
    width: -webkit-max-content;
    width: max-content;
}
.w-\[95\%\]{
    width: 95%;
}
.w-28{
    width: 7rem;
}
.w-16{
    width: 4rem;
}
.w-64{
    width: 16rem;
}
.w-\[1em\]{
    width: 1em;
}
.w-4{
    width: 1rem;
}
.w-3\/4{
    width: 75%;
}
.w-12{
    width: 3rem;
}
.w-4\/5{
    width: 80%;
}
.min-w-\[100px\]{
    min-width: 100px;
}
.min-w-\[30px\]{
    min-width: 30px;
}
.min-w-\[350px\]{
    min-width: 350px;
}
.min-w-\[152px\]{
    min-width: 152px;
}
.max-w-\[530px\]{
    max-width: 530px;
}
.max-w-2xl{
    max-width: 42rem;
}
.max-w-\[350px\]{
    max-width: 350px;
}
.max-w-\[152px\]{
    max-width: 152px;
}
.max-w-full{
    max-width: 100%;
}
.max-w-\[400px\]{
    max-width: 400px;
}
.max-w-\[100vw\]{
    max-width: 100vw;
}
.max-w-sm{
    max-width: 24rem;
}
.max-w-\[250px\]{
    max-width: 250px;
}
.flex-1{
    flex: 1 1 0%;
}
.flex-none{
    flex: none;
}
.flex-shrink-0{
    flex-shrink: 0;
}
.flex-grow{
    flex-grow: 1;
}
.border-collapse{
    border-collapse: collapse;
}
.origin-top-left{
    -webkit-transform-origin: top left;
            transform-origin: top left;
}
.translate-y-full{
    --tw-translate-y: 100%;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}
.translate-y-0{
    --tw-translate-y: 0px;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}
.-translate-x-1{
    --tw-translate-x: -0.25rem;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}
.translate-y-\[-2px\]{
    --tw-translate-y: -2px;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}
.translate-y-\[19px\]{
    --tw-translate-y: 19px;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}
.rotate-\[-180deg\]{
    --tw-rotate: -180deg;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}
.scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}
.scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}
.scale-\[\.4\]{
    --tw-scale-x: .4;
    --tw-scale-y: .4;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}
.transform{
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}
@-webkit-keyframes pulse{

    50%{
        opacity: .5;
    }
}
@keyframes pulse{

    50%{
        opacity: .5;
    }
}
.animate-pulse{
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@-webkit-keyframes shrink-grow{

    0%, 100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    50%{
        -webkit-transform: scale(0.1);
                transform: scale(0.1);
    }
}
@keyframes shrink-grow{

    0%, 100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    50%{
        -webkit-transform: scale(0.1);
                transform: scale(0.1);
    }
}
.animate-shrink-grow{
    -webkit-animation: shrink-grow 0.75s 0.12s infinite cubic-bezier(0.2,0.68,0.18,1.08);
            animation: shrink-grow 0.75s 0.12s infinite cubic-bezier(0.2,0.68,0.18,1.08);
}
.animate-shrink-grow-100{
    -webkit-animation: shrink-grow 0.75s 0.24s infinite cubic-bezier(0.2,0.68,0.18,1.08);
            animation: shrink-grow 0.75s 0.24s infinite cubic-bezier(0.2,0.68,0.18,1.08);
}
.animate-shrink-grow-200{
    -webkit-animation: shrink-grow 0.75s 0.36s infinite cubic-bezier(0.2,0.68,0.18,1.08);
            animation: shrink-grow 0.75s 0.36s infinite cubic-bezier(0.2,0.68,0.18,1.08);
}
.cursor-pointer{
    cursor: pointer;
}
.cursor-not-allowed{
    cursor: not-allowed;
}
.cursor-auto{
    cursor: auto;
}
.cursor-wait{
    cursor: wait;
}
.list-none{
    list-style-type: none;
}
.appearance-none{
    -webkit-appearance: none;
            appearance: none;
}
.grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-none{
    grid-template-columns: none;
}
.grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.flex-row{
    flex-direction: row;
}
.flex-row-reverse{
    flex-direction: row-reverse;
}
.flex-col{
    flex-direction: column;
}
.flex-wrap{
    flex-wrap: wrap;
}
.place-items-center{
    place-items: center;
}
.content-end{
    align-content: flex-end;
}
.items-start{
    align-items: flex-start;
}
.items-end{
    align-items: flex-end;
}
.items-center{
    align-items: center;
}
.items-stretch{
    align-items: stretch;
}
.justify-start{
    justify-content: flex-start;
}
.justify-end{
    justify-content: flex-end;
}
.justify-center{
    justify-content: center;
}
.justify-between{
    justify-content: space-between;
}
.gap-4{
    gap: 1rem;
}
.gap-5{
    gap: 1.25rem;
}
.gap-1\.5{
    gap: 0.375rem;
}
.gap-1{
    gap: 0.25rem;
}
.gap-3{
    gap: 0.75rem;
}
.gap-8{
    gap: 2rem;
}
.gap-2{
    gap: 0.5rem;
}
.gap-6{
    gap: 1.5rem;
}
.gap-\[20px\]{
    gap: 20px;
}
.gap-\[15px\]{
    gap: 15px;
}
.space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.overflow-auto{
    overflow: auto;
}
.overflow-hidden{
    overflow: hidden;
}
.overflow-visible{
    overflow: visible;
}
.overflow-scroll{
    overflow: scroll;
}
.overflow-x-auto{
    overflow-x: auto;
}
.overflow-y-auto{
    overflow-y: auto;
}
.whitespace-nowrap{
    white-space: nowrap;
}
.break-words{
    overflow-wrap: break-word;
}
.break-all{
    word-break: break-all;
}
.rounded-lg{
    border-radius: 0.5rem;
}
.rounded{
    border-radius: 0.25rem;
}
.rounded-full{
    border-radius: 9999px;
}
.rounded-md{
    border-radius: 0.375rem;
}
.rounded-xl{
    border-radius: 0.75rem;
}
.rounded-\[14px\]{
    border-radius: 14px;
}
.rounded-2xl{
    border-radius: 1rem;
}
.rounded-sm{
    border-radius: 0.125rem;
}
.rounded-t-2xl{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.rounded-t-\[14px\]{
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}
.border-\[1px\]{
    border-width: 1px;
}
.border{
    border-width: 1px;
}
.border-0{
    border-width: 0px;
}
.border-2{
    border-width: 2px;
}
.border-\[0\.5px\]{
    border-width: 0.5px;
}
.border-\[0px\]{
    border-width: 0px;
}
.border-b-\[1px\]{
    border-bottom-width: 1px;
}
.border-l-0{
    border-left-width: 0px;
}
.border-r-0{
    border-right-width: 0px;
}
.border-b-0{
    border-bottom-width: 0px;
}
.border-t{
    border-top-width: 1px;
}
.border-t-\[1px\]{
    border-top-width: 1px;
}
.border-b{
    border-bottom-width: 1px;
}
.border-r{
    border-right-width: 1px;
}
.border-l{
    border-left-width: 1px;
}
.border-t-0{
    border-top-width: 0px;
}
.border-b-2{
    border-bottom-width: 2px;
}
.border-solid{
    border-style: solid;
}
.border-none{
    border-style: none;
}
.border-neutral-200{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-neutral-200) / var(--twc-neutral-200-opacity, var(--tw-border-opacity)));
}
.border-neutral-100{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-neutral-100) / var(--twc-neutral-100-opacity, var(--tw-border-opacity)));
}
.border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.border-primary-500{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-primary-500) / var(--twc-primary-500-opacity, var(--tw-border-opacity)));
}
.border-asparagus-500{
    --tw-border-opacity: 1;
    border-color: rgba(140, 173, 77, var(--tw-border-opacity));
}
.border-navy-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(0, 113, 188, var(--tw-border-opacity));
}
.border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.border-neutral-300{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-neutral-300) / var(--twc-neutral-300-opacity, var(--tw-border-opacity)));
}
.border-error-500{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-error-500) / var(--twc-error-500-opacity, var(--tw-border-opacity)));
}
.border-neutral-500{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-neutral-500) / var(--twc-neutral-500-opacity, var(--tw-border-opacity)));
}
.border-neutral-400{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-neutral-400) / var(--twc-neutral-400-opacity, var(--tw-border-opacity)));
}
.border-transparent{
    border-color: transparent;
}
.border-primary_v2-600{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-primary_v2-600) / var(--twc-primary_v2-600-opacity, var(--tw-border-opacity)));
}
.border-primary-600{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-primary-600) / var(--twc-primary-600-opacity, var(--tw-border-opacity)));
}
.border-primary-100{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-primary-100) / var(--twc-primary-100-opacity, var(--tw-border-opacity)));
}
.border-t-neutral-200{
    --tw-border-opacity: 1;
    border-top-color: hsl(var(--twc-neutral-200) / var(--twc-neutral-200-opacity, var(--tw-border-opacity)));
}
.border-t-neutral-100{
    --tw-border-opacity: 1;
    border-top-color: hsl(var(--twc-neutral-100) / var(--twc-neutral-100-opacity, var(--tw-border-opacity)));
}
.border-b-black{
    --tw-border-opacity: 1;
    border-bottom-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.border-opacity-0{
    --tw-border-opacity: 0;
}
.bg-warning-100{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-warning-100) / var(--twc-warning-100-opacity, var(--tw-bg-opacity)));
}
.bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-primary-50{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-50) / var(--twc-primary-50-opacity, var(--tw-bg-opacity)));
}
.bg-neutral-50{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-50) / var(--twc-neutral-50-opacity, var(--tw-bg-opacity)));
}
.bg-transparent{
    background-color: transparent;
}
.bg-neutral-100{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-100) / var(--twc-neutral-100-opacity, var(--tw-bg-opacity)));
}
.bg-asparagus-500{
    --tw-bg-opacity: 1;
    background-color: rgba(140, 173, 77, var(--tw-bg-opacity));
}
.bg-navy-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 113, 188, var(--tw-bg-opacity));
}
.bg-teal-50{
    --tw-bg-opacity: 1;
    background-color: rgba(228, 245, 244, var(--tw-bg-opacity));
}
.bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-primary-500{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-500) / var(--twc-primary-500-opacity, var(--tw-bg-opacity)));
}
.bg-info-500{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-info-500) / var(--twc-info-500-opacity, var(--tw-bg-opacity)));
}
.bg-info-200{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-info-200) / var(--twc-info-200-opacity, var(--tw-bg-opacity)));
}
.bg-error-500{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-error-500) / var(--twc-error-500-opacity, var(--tw-bg-opacity)));
}
.bg-neutral-0{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-0) / var(--twc-neutral-0-opacity, var(--tw-bg-opacity)));
}
.bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.bg-brand-600{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-brand-600) / var(--twc-brand-600-opacity, var(--tw-bg-opacity)));
}
.bg-neutral-500{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-500) / var(--twc-neutral-500-opacity, var(--tw-bg-opacity)));
}
.bg-primary_v2-50{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary_v2-50) / var(--twc-primary_v2-50-opacity, var(--tw-bg-opacity)));
}
.bg-primary-600{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-600) / var(--twc-primary-600-opacity, var(--tw-bg-opacity)));
}
.bg-neutral-200{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-200) / var(--twc-neutral-200-opacity, var(--tw-bg-opacity)));
}
.bg-primary-100{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-100) / var(--twc-primary-100-opacity, var(--tw-bg-opacity)));
}
.bg-primary_v2-600{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary_v2-600) / var(--twc-primary_v2-600-opacity, var(--tw-bg-opacity)));
}
.bg-current{
    background-color: currentColor;
}
.bg-info-100{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-info-100) / var(--twc-info-100-opacity, var(--tw-bg-opacity)));
}
.bg-success-100{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-success-100) / var(--twc-success-100-opacity, var(--tw-bg-opacity)));
}
.bg-opacity-0{
    --tw-bg-opacity: 0;
}
.bg-opacity-60{
    --tw-bg-opacity: 0.6;
}
.bg-opacity-25{
    --tw-bg-opacity: 0.25;
}
.bg-opacity-20{
    --tw-bg-opacity: 0.2;
}
.stroke-2{
    stroke-width: 2;
}
.object-cover{
    object-fit: cover;
}
.p-4{
    padding: 1rem;
}
.p-3{
    padding: 0.75rem;
}
.p-0{
    padding: 0px;
}
.p-1{
    padding: 0.25rem;
}
.p-2{
    padding: 0.5rem;
}
.p-8{
    padding: 2rem;
}
.p-5{
    padding: 1.25rem;
}
.p-6{
    padding: 1.5rem;
}
.p-0\.5{
    padding: 0.125rem;
}
.px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.py-2\.5{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
.py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-1\.5{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.px-3\.5{
    padding-left: 0.875rem;
    padding-right: 0.875rem;
}
.py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.px-0{
    padding-left: 0px;
    padding-right: 0px;
}
.py-4{
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-8{
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.py-0{
    padding-top: 0px;
    padding-bottom: 0px;
}
.px-20{
    padding-left: 5rem;
    padding-right: 5rem;
}
.px-4{
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-1\.5{
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}
.px-7{
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}
.py-0\.5{
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}
.py-3\.5{
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}
.px-2\.5{
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}
.pt-4{
    padding-top: 1rem;
}
.pl-0{
    padding-left: 0px;
}
.pr-1{
    padding-right: 0.25rem;
}
.pl-3\.5{
    padding-left: 0.875rem;
}
.pl-3{
    padding-left: 0.75rem;
}
.pl-8{
    padding-left: 2rem;
}
.pr-8{
    padding-right: 2rem;
}
.pr-3\.5{
    padding-right: 0.875rem;
}
.pr-3{
    padding-right: 0.75rem;
}
.pl-5{
    padding-left: 1.25rem;
}
.pt-1{
    padding-top: 0.25rem;
}
.pl-2{
    padding-left: 0.5rem;
}
.pt-24{
    padding-top: 6rem;
}
.pl-9{
    padding-left: 2.25rem;
}
.pb-3{
    padding-bottom: 0.75rem;
}
.pb-6{
    padding-bottom: 1.5rem;
}
.pt-6{
    padding-top: 1.5rem;
}
.pb-4{
    padding-bottom: 1rem;
}
.pl-4{
    padding-left: 1rem;
}
.pb-2{
    padding-bottom: 0.5rem;
}
.pl-1{
    padding-left: 0.25rem;
}
.pb-0{
    padding-bottom: 0px;
}
.pt-5{
    padding-top: 1.25rem;
}
.pt-3{
    padding-top: 0.75rem;
}
.pt-2{
    padding-top: 0.5rem;
}
.pt-7{
    padding-top: 1.75rem;
}
.pb-1{
    padding-bottom: 0.25rem;
}
.pt-10{
    padding-top: 2.5rem;
}
.pt-8{
    padding-top: 2rem;
}
.pt-0{
    padding-top: 0px;
}
.pb-5{
    padding-bottom: 1.25rem;
}
.pb-px{
    padding-bottom: 1px;
}
.pr-2{
    padding-right: 0.5rem;
}
.pl-2\.5{
    padding-left: 0.625rem;
}
.pt-9{
    padding-top: 2.25rem;
}
.pr-5{
    padding-right: 1.25rem;
}
.pr-4{
    padding-right: 1rem;
}
.pr-9{
    padding-right: 2.25rem;
}
.pt-\[15px\]{
    padding-top: 15px;
}
.pb-1\.5{
    padding-bottom: 0.375rem;
}
.pl-\[3px\]{
    padding-left: 3px;
}
.pt-\[5px\]{
    padding-top: 5px;
}
.text-left{
    text-align: left;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-justify{
    text-align: justify;
}
.align-middle{
    vertical-align: middle;
}
.font-manrope{
    font-family: Manrope, sans-serif;
}
.text-md{
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-sm{
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-2xl{
    font-size: 1.563rem;
    line-height: 1.8rem;
}
.text-lg{
    font-size: 1.125rem;
    line-height: 1.5rem;
}
.text-base{
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-xl{
    font-size: 1.25rem;
    line-height: 1.5rem;
}
.text-xs{
    font-size: 0.75rem;
    line-height: 1.125rem;
}
.text-3xl{
    font-size: 1.688rem;
}
.text-display-xs{
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-display-lg{
    font-size: 3rem;
    line-height: 3.75rem;
    letter-spacing: -0.02em;
}
.text-\[15px\]{
    font-size: 15px;
}
.font-semibold{
    font-weight: 600;
}
.font-bold{
    font-weight: 700;
}
.font-normal{
    font-weight: 400;
}
.font-medium{
    font-weight: 500;
}
.uppercase{
    text-transform: uppercase;
}
.lowercase{
    text-transform: lowercase;
}
.italic{
    font-style: italic;
}
.leading-none{
    line-height: 1;
}
.tracking-\[0\.2px\]{
    letter-spacing: 0.2px;
}
.text-neutral-50{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-50) / var(--twc-neutral-50-opacity, var(--tw-text-opacity)));
}
.text-neutral-500{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-500) / var(--twc-neutral-500-opacity, var(--tw-text-opacity)));
}
.text-primary-500{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-primary-500) / var(--twc-primary-500-opacity, var(--tw-text-opacity)));
}
.text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-asparagus-500{
    --tw-text-opacity: 1;
    color: rgba(140, 173, 77, var(--tw-text-opacity));
}
.text-navy-blue-600{
    --tw-text-opacity: 1;
    color: rgba(0, 113, 188, var(--tw-text-opacity));
}
.text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-current{
    color: currentColor;
}
.text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-error-500{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-error-500) / var(--twc-error-500-opacity, var(--tw-text-opacity)));
}
.text-neutral-300{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-300) / var(--twc-neutral-300-opacity, var(--tw-text-opacity)));
}
.text-neutral-400{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-400) / var(--twc-neutral-400-opacity, var(--tw-text-opacity)));
}
.text-info-500{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-info-500) / var(--twc-info-500-opacity, var(--tw-text-opacity)));
}
.text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.text-neutral-0{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-0) / var(--twc-neutral-0-opacity, var(--tw-text-opacity)));
}
.text-warning-500{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-warning-500) / var(--twc-warning-500-opacity, var(--tw-text-opacity)));
}
.text-tertiary-500{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-tertiary-500) / var(--twc-tertiary-500-opacity, var(--tw-text-opacity)));
}
.text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.text-primary-600{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-primary-600) / var(--twc-primary-600-opacity, var(--tw-text-opacity)));
}
.text-warning-200{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-warning-200) / var(--twc-warning-200-opacity, var(--tw-text-opacity)));
}
.text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-neutral-200{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-200) / var(--twc-neutral-200-opacity, var(--tw-text-opacity)));
}
.text-foreground-neutral-0{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-foreground-neutral-0) / var(--twc-foreground-neutral-0-opacity, var(--tw-text-opacity)));
}
.text-foreground-neutral-400{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-foreground-neutral-400) / var(--twc-foreground-neutral-400-opacity, var(--tw-text-opacity)));
}
.text-foreground-neutral_v2-0{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-foreground-neutral_v2-0) / var(--twc-foreground-neutral_v2-0-opacity, var(--tw-text-opacity)));
}
.text-transparent{
    color: transparent;
}
.text-primary_v2-500{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-primary_v2-500) / var(--twc-primary_v2-500-opacity, var(--tw-text-opacity)));
}
.text-neutral-000{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-000) / var(--twc-neutral-000-opacity, var(--tw-text-opacity)));
}
.text-info-100{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-info-100) / var(--twc-info-100-opacity, var(--tw-text-opacity)));
}
.underline{
    text-decoration: underline;
}
.no-underline{
    text-decoration: none;
}
.placeholder-gray-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}
.placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}
.opacity-75{
    opacity: 0.75;
}
.shadow-xl{
    --tw-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-2xl{
    --tw-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg{
    --tw-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-fluffy-l{
    --tw-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md{
    --tw-shadow: 0px 1px 5px rgba(0, 0, 0, 0.14);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xs{
    --tw-shadow: 0px 1px 3px rgba(126, 126, 126, 0.3);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
    -webkit-filter: var(--tw-filter);
            filter: var(--tw-filter);
}
.grayscale{
    --tw-grayscale: grayscale(100%);
    -webkit-filter: var(--tw-filter);
            filter: var(--tw-filter);
}
.filter{
    -webkit-filter: var(--tw-filter);
            filter: var(--tw-filter);
}
.transition-all{
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-transform{
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.duration-300{
    transition-duration: 300ms;
}
.duration-200{
    transition-duration: 200ms;
}
.ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-\[cubic-bezier\(0\2c 0\2c 0\.2\2c 1\)\]{
    transition-timing-function: cubic-bezier(0,0,0.2,1);
}
.line-clamp-1{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.line-clamp-2{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.flexi,[data-theme="flexi"]{
    --twc-primary-50: 176.5 45.9% 92.7%;
    --twc-primary-100: 177 25.6% 84.7%;
    --twc-primary-500: 177 81.8% 23.7%;
    --twc-primary-600: 177 85.1% 18.4%;
    --twc-primary-700: 176.70000000000005 90.2% 12%;
    --twc-primary_v2-50: 176.5 45.9% 92.7%;
    --twc-primary_v2-100: 177 25.6% 84.7%;
    --twc-primary_v2-500: 177 81.8% 23.7%;
    --twc-primary_v2-600: 177 85.1% 18.4%;
    --twc-primary_v2-700: 176.70000000000005 90.2% 12%;
    --twc-foreground-primary-50: 0 0% 100%;
    --twc-foreground-primary-100: 0 0% 100%;
    --twc-foreground-primary-500: 0 0% 100%;
    --twc-foreground-primary-600: 0 0% 100%;
    --twc-foreground-primary-700: 0 0% 100%;
    --twc-foreground-neutral-0: 0 0% 100%;
    --twc-foreground-neutral-50: 0 0% 96.1%;
    --twc-foreground-neutral-70: 0 0% 93.3%;
    --twc-foreground-neutral-100: 60 1.3% 84.9%;
    --twc-foreground-neutral-200: 120 0.7% 70%;
    --twc-foreground-neutral-300: 60 0.4% 54.7%;
    --twc-foreground-neutral-400: 60 0.5% 39.8%;
    --twc-foreground-neutral-500: 150 3.4% 11.4%;
    --twc-foreground-neutral-600: 150 3.4% 11.4%;
    --twc-foreground-neutral-000: 177.10000000000002 16.2% 50.4%;
    --twc-foreground-neutral_v2-0: 0 0% 100%;
    --twc-foreground-neutral_v2-50: 0 0% 96.1%;
    --twc-foreground-neutral_v2-70: 0 0% 93.3%;
    --twc-foreground-neutral_v2-100: 60 1.3% 84.9%;
    --twc-foreground-neutral_v2-200: 120 0.7% 70%;
    --twc-foreground-neutral_v2-300: 60 0.4% 54.7%;
    --twc-foreground-neutral_v2-400: 60 0.5% 39.8%;
    --twc-foreground-neutral_v2-500: 150 3.4% 11.4%;
    --twc-foreground-neutral_v2-600: 150 3.4% 11.4%;
    --twc-foreground-neutral_v2-000: 177.10000000000002 16.2% 50.4%;
    --twc-tertiary-50: 51.80000000000001 97.3% 85.3%;
    --twc-tertiary-100: 51.60000000000002 97.9% 81.4%;
    --twc-tertiary-500: 177 81.8% 23.7%;
    --twc-tertiary-600: 51.30000000000001 98% 38.4%;
    --twc-tertiary-700: 51.60000000000002 97.1% 27.5%;
    --twc-neutral-0: 0 0% 100%;
    --twc-neutral-50: 0 0% 96.1%;
    --twc-neutral-70: 0 0% 93.3%;
    --twc-neutral-100: 60 1.3% 84.9%;
    --twc-neutral-200: 120 0.7% 70%;
    --twc-neutral-300: 60 0.4% 54.7%;
    --twc-neutral-400: 60 0.5% 39.8%;
    --twc-neutral-500: 150 3.4% 11.4%;
    --twc-neutral-600: 150 3.4% 11.4%;
    --twc-neutral-000: 177.10000000000002 16.2% 50.4%;
    --twc-success-100: 162.89999999999998 28% 90.2%;
    --twc-success-500: 162 98.4% 23.9%;
    --twc-warning-100: 38.80000000000001 96.2% 89.6%;
    --twc-warning-200: 23 100% 45.1%;
    --twc-warning-500: 38.10000000000002 98.4% 49.6%;
    --twc-error-100: 8.199999999999989 68.8% 93.7%;
    --twc-error-500: 7.800000000000011 67% 45.1%;
    --twc-info-100: 200.60000000000002 49.3% 86.1%;
    --twc-info-200: 220 100% 95.3%;
    --twc-info-500: 200 100% 30%;
    --twc-brand-500: 177 81.8% 23.7%;
    --twc-brand-600: 177 85.1% 18.4%;
}
:root{
    --twc-primary-50: 176.5 45.9% 92.7%;
    --twc-primary-100: 177 25.6% 84.7%;
    --twc-primary-500: 177 81.8% 23.7%;
    --twc-primary-600: 177 85.1% 18.4%;
    --twc-primary-700: 176.70000000000005 90.2% 12%;
    --twc-primary_v2-50: 176.5 45.9% 92.7%;
    --twc-primary_v2-100: 177 25.6% 84.7%;
    --twc-primary_v2-500: 177 81.8% 23.7%;
    --twc-primary_v2-600: 177 85.1% 18.4%;
    --twc-primary_v2-700: 176.70000000000005 90.2% 12%;
    --twc-foreground-primary-50: 0 0% 100%;
    --twc-foreground-primary-100: 0 0% 100%;
    --twc-foreground-primary-500: 0 0% 100%;
    --twc-foreground-primary-600: 0 0% 100%;
    --twc-foreground-primary-700: 0 0% 100%;
    --twc-foreground-neutral-0: 0 0% 100%;
    --twc-foreground-neutral-50: 0 0% 96.1%;
    --twc-foreground-neutral-70: 0 0% 93.3%;
    --twc-foreground-neutral-100: 60 1.3% 84.9%;
    --twc-foreground-neutral-200: 120 0.7% 70%;
    --twc-foreground-neutral-300: 60 0.4% 54.7%;
    --twc-foreground-neutral-400: 60 0.5% 39.8%;
    --twc-foreground-neutral-500: 150 3.4% 11.4%;
    --twc-foreground-neutral-600: 150 3.4% 11.4%;
    --twc-foreground-neutral-000: 177.10000000000002 16.2% 50.4%;
    --twc-foreground-neutral_v2-0: 0 0% 100%;
    --twc-foreground-neutral_v2-50: 0 0% 96.1%;
    --twc-foreground-neutral_v2-70: 0 0% 93.3%;
    --twc-foreground-neutral_v2-100: 60 1.3% 84.9%;
    --twc-foreground-neutral_v2-200: 120 0.7% 70%;
    --twc-foreground-neutral_v2-300: 60 0.4% 54.7%;
    --twc-foreground-neutral_v2-400: 60 0.5% 39.8%;
    --twc-foreground-neutral_v2-500: 150 3.4% 11.4%;
    --twc-foreground-neutral_v2-600: 150 3.4% 11.4%;
    --twc-foreground-neutral_v2-000: 177.10000000000002 16.2% 50.4%;
    --twc-tertiary-50: 51.80000000000001 97.3% 85.3%;
    --twc-tertiary-100: 51.60000000000002 97.9% 81.4%;
    --twc-tertiary-500: 177 81.8% 23.7%;
    --twc-tertiary-600: 51.30000000000001 98% 38.4%;
    --twc-tertiary-700: 51.60000000000002 97.1% 27.5%;
    --twc-neutral-0: 0 0% 100%;
    --twc-neutral-50: 0 0% 96.1%;
    --twc-neutral-70: 0 0% 93.3%;
    --twc-neutral-100: 60 1.3% 84.9%;
    --twc-neutral-200: 120 0.7% 70%;
    --twc-neutral-300: 60 0.4% 54.7%;
    --twc-neutral-400: 60 0.5% 39.8%;
    --twc-neutral-500: 150 3.4% 11.4%;
    --twc-neutral-600: 150 3.4% 11.4%;
    --twc-neutral-000: 177.10000000000002 16.2% 50.4%;
    --twc-success-100: 162.89999999999998 28% 90.2%;
    --twc-success-500: 162 98.4% 23.9%;
    --twc-warning-100: 38.80000000000001 96.2% 89.6%;
    --twc-warning-200: 23 100% 45.1%;
    --twc-warning-500: 38.10000000000002 98.4% 49.6%;
    --twc-error-100: 8.199999999999989 68.8% 93.7%;
    --twc-error-500: 7.800000000000011 67% 45.1%;
    --twc-info-100: 200.60000000000002 49.3% 86.1%;
    --twc-info-200: 220 100% 95.3%;
    --twc-info-500: 200 100% 30%;
    --twc-brand-500: 177 81.8% 23.7%;
    --twc-brand-600: 177 85.1% 18.4%;
}
.cdfpay,[data-theme="cdfpay"]{
    --twc-primary-50: 213 74.4% 67.8%;
    --twc-primary-100: 212.79999999999995 74.1% 63.7%;
    --twc-primary-500: 213.10000000000002 73.6% 20.8%;
    --twc-primary-600: 213 74.1% 31.8%;
    --twc-primary-700: 212.39999999999998 75.5% 9.6%;
    --twc-primary_v2-50: 51.5 97.6% 49.4%;
    --twc-primary_v2-100: 51.60000000000002 97.9% 81.4%;
    --twc-primary_v2-500: 51.5 97.6% 49.4%;
    --twc-primary_v2-600: 51.5 97.6% 49.4%;
    --twc-primary_v2-700: 51.60000000000002 97.1% 27.5%;
    --twc-foreground-primary-50: 0 0% 0%;
    --twc-foreground-primary-100: 0 0% 0%;
    --twc-foreground-primary-500: 0 0% 0%;
    --twc-foreground-primary-600: 0 0% 0%;
    --twc-foreground-primary-700: 0 0% 0%;
    --twc-foreground-neutral-0: 0 0% 100%;
    --twc-foreground-neutral-50: 0 0% 96.1%;
    --twc-foreground-neutral-100: 60 1.3% 84.9%;
    --twc-foreground-neutral-200: 120 0.7% 70%;
    --twc-foreground-neutral-300: 60 0.4% 54.7%;
    --twc-foreground-neutral-400: 60 0.5% 39.8%;
    --twc-foreground-neutral-500: 150 3.4% 11.4%;
    --twc-foreground-neutral-600: 150 3.4% 11.4%;
    --twc-foreground-neutral-000: 177.10000000000002 16.2% 50.4%;
    --twc-foreground-neutral_v2-0: 150 3.4% 11.4%;
    --twc-foreground-neutral_v2-50: 0 0% 96.1%;
    --twc-foreground-neutral_v2-100: 60 1.3% 84.9%;
    --twc-foreground-neutral_v2-200: 120 0.7% 70%;
    --twc-foreground-neutral_v2-300: 60 0.4% 54.7%;
    --twc-foreground-neutral_v2-400: 60 0.5% 39.8%;
    --twc-foreground-neutral_v2-500: 150 3.4% 11.4%;
    --twc-foreground-neutral_v2-600: 150 3.4% 11.4%;
    --twc-foreground-neutral_v2-000: 177.10000000000002 16.2% 50.4%;
    --twc-tertiary-50: 51.80000000000001 97.3% 85.3%;
    --twc-tertiary-100: 51.60000000000002 97.9% 81.4%;
    --twc-tertiary-500: 213 74.1% 31.8%;
    --twc-tertiary-600: 51.30000000000001 98% 38.4%;
    --twc-tertiary-700: 51.60000000000002 97.1% 27.5%;
    --twc-neutral-0: 0 0% 100%;
    --twc-neutral-50: 0 0% 96.1%;
    --twc-neutral-70: 0 0% 93.3%;
    --twc-neutral-100: 60 1.3% 84.9%;
    --twc-neutral-200: 120 0.7% 70%;
    --twc-neutral-300: 60 0.4% 54.7%;
    --twc-neutral-400: 60 0.5% 39.8%;
    --twc-neutral-500: 150 3.4% 11.4%;
    --twc-neutral-600: 150 3.4% 11.4%;
    --twc-neutral-000: 177.10000000000002 16.2% 50.4%;
    --twc-success-100: 162.89999999999998 28% 90.2%;
    --twc-success-500: 162 98.4% 23.9%;
    --twc-warning-100: 38.80000000000001 96.2% 89.6%;
    --twc-warning-200: 23 100% 45.1%;
    --twc-warning-500: 38.10000000000002 98.4% 49.6%;
    --twc-error-100: 8.199999999999989 68.8% 93.7%;
    --twc-error-500: 7.800000000000011 67% 45.1%;
    --twc-info-100: 200.60000000000002 49.3% 86.1%;
    --twc-info-500: 200 100% 30%;
    --twc-brand-500: 213 74.1% 31.8%;
    --twc-brand-600: 213 74.1% 31.8%;
}
.inloop,[data-theme="inloop"]{
    --twc-primary-50: 215.79999999999995 60.8% 60%;
    --twc-primary-100: 216.20000000000005 60.7% 56.1%;
    --twc-primary-500: 216 61% 24.1%;
    --twc-primary-600: 216.60000000000002 61.2% 13.1%;
    --twc-primary-700: 214.29999999999995 63.6% 2.2%;
    --twc-primary_v2-50: 215.79999999999995 60.8% 60%;
    --twc-primary_v2-100: 216.20000000000005 60.7% 56.1%;
    --twc-primary_v2-500: 216 61% 24.1%;
    --twc-primary_v2-600: 216.60000000000002 61.2% 13.1%;
    --twc-primary_v2-700: 214.29999999999995 63.6% 2.2%;
    --twc-foreground-primary-50: 0 0% 100%;
    --twc-foreground-primary-100: 0 0% 100%;
    --twc-foreground-primary-500: 0 0% 100%;
    --twc-foreground-primary-600: 0 0% 100%;
    --twc-foreground-primary-700: 0 0% 100%;
    --twc-foreground-neutral-0: 0 0% 100%;
    --twc-foreground-neutral-50: 0 0% 96.1%;
    --twc-foreground-neutral-70: 0 0% 93.3%;
    --twc-foreground-neutral-100: 60 1.3% 84.9%;
    --twc-foreground-neutral-200: 120 0.7% 70%;
    --twc-foreground-neutral-300: 60 0.4% 54.7%;
    --twc-foreground-neutral-400: 60 0.5% 39.8%;
    --twc-foreground-neutral-500: 150 3.4% 11.4%;
    --twc-foreground-neutral-600: 150 3.4% 11.4%;
    --twc-foreground-neutral-000: 177.10000000000002 16.2% 50.4%;
    --twc-foreground-neutral_v2-0: 0 0% 100%;
    --twc-foreground-neutral_v2-50: 0 0% 96.1%;
    --twc-foreground-neutral_v2-70: 0 0% 93.3%;
    --twc-foreground-neutral_v2-100: 60 1.3% 84.9%;
    --twc-foreground-neutral_v2-200: 120 0.7% 70%;
    --twc-foreground-neutral_v2-300: 60 0.4% 54.7%;
    --twc-foreground-neutral_v2-400: 60 0.5% 39.8%;
    --twc-foreground-neutral_v2-500: 150 3.4% 11.4%;
    --twc-foreground-neutral_v2-600: 150 3.4% 11.4%;
    --twc-foreground-neutral_v2-000: 177.10000000000002 16.2% 50.4%;
    --twc-tertiary-50: 51.80000000000001 97.3% 85.3%;
    --twc-tertiary-100: 51.60000000000002 97.9% 81.4%;
    --twc-tertiary-500: 213 74.1% 31.8%;
    --twc-tertiary-600: 51.30000000000001 98% 38.4%;
    --twc-tertiary-700: 51.60000000000002 97.1% 27.5%;
    --twc-neutral-0: 0 0% 100%;
    --twc-neutral-50: 0 0% 96.1%;
    --twc-neutral-70: 0 0% 93.3%;
    --twc-neutral-100: 60 1.3% 84.9%;
    --twc-neutral-200: 120 0.7% 70%;
    --twc-neutral-300: 60 0.4% 54.7%;
    --twc-neutral-400: 60 0.5% 39.8%;
    --twc-neutral-500: 150 3.4% 11.4%;
    --twc-neutral-600: 150 3.4% 11.4%;
    --twc-neutral-000: 177.10000000000002 16.2% 50.4%;
    --twc-success-100: 162.89999999999998 28% 90.2%;
    --twc-success-500: 162 98.4% 23.9%;
    --twc-warning-100: 38.80000000000001 96.2% 89.6%;
    --twc-warning-200: 23 100% 45.1%;
    --twc-warning-500: 38.10000000000002 98.4% 49.6%;
    --twc-error-100: 8.199999999999989 68.8% 93.7%;
    --twc-error-500: 7.800000000000011 67% 45.1%;
    --twc-info-100: 200.60000000000002 49.3% 86.1%;
    --twc-info-500: 200 100% 30%;
    --twc-brand-500: 213 74.1% 31.8%;
    --twc-brand-600: 213 74.1% 31.8%;
}
.snap-mandatory {
    --tw-scroll-snap-strictness: mandatory;
  }
.snap-center {
    scroll-snap-align: center;
  }
@media screen {
    /* For Webkit-based browsers (Chrome, Safari and Opera) */
    .scrollbar-hidden::-webkit-scrollbar {
      display: none;
    }

    /* For IE, Edge and Firefox */
    .scrollbar-hidden {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

/* make modal overlap everything
  previously it was being overlapped by the toolbar
  and the open in app message bar
 */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f2f2f2;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c0cece;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c0cece;
}

/* handle last pass iframe covering the entire screen on mobile  */
@media screen and (max-width: 768px) {
  [data-lastpass-save] {
    display: none !important;
    pointer-events: none;
    z-index: -1;
  }
}

.box-border-all,
.box-border-all * {
  box-sizing: border-box;
}

/* to prevent background scroll and weird positioning when dialog is open */
html:has(dialog[open]:modal) {
  overflow: hidden;
}

/* handle last pass iframe covering the entire screen on mobile  */
@media screen and (max-width: 768px) {
  [data-lastpass-save] {
    display: none !important;
    pointer-events: none;
    z-index: -1;
  }
}

/* only applied on ipad with retina display */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 720px) and (max-width: 1024px),
  only screen and (min-device-pixel-ratio: 2) and (min-width: 720px) and (max-width: 1024px),
  only screen and (min-resolution: 192dpi) and (min-width: 720px) and (max-width: 1024px),
  only screen and (min-resolution: 2dppx) and (min-width: 720px) and (max-width: 1024px) {
  .\!ipad\:max-w-full {
    max-width: 100% !important;
  }
}

a{
    cursor: pointer;
    --tw-text-opacity: 1;
    color: hsl(var(--twc-brand-500) / var(--twc-brand-500-opacity, var(--tw-text-opacity)));
    text-decoration: underline;
}

.visited\:text-neutral-500:visited{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-500) / var(--twc-neutral-500-opacity, var(--tw-text-opacity)));
}

.checked\:border-primary-500:checked{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-primary-500) / var(--twc-primary-500-opacity, var(--tw-border-opacity)));
}

.checked\:border-primary-600:checked{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-primary-600) / var(--twc-primary-600-opacity, var(--tw-border-opacity)));
}

.checked\:bg-primary-500:checked{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-500) / var(--twc-primary-500-opacity, var(--tw-bg-opacity)));
}

.checked\:bg-primary-100:checked{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-100) / var(--twc-primary-100-opacity, var(--tw-bg-opacity)));
}

.hover\:cursor-pointer:hover{
    cursor: pointer;
}

.hover\:border-error-500:hover{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-error-500) / var(--twc-error-500-opacity, var(--tw-border-opacity)));
}

.hover\:border-primary-700:hover{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-primary-700) / var(--twc-primary-700-opacity, var(--tw-border-opacity)));
}

.hover\:border-neutral-600:hover{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-neutral-600) / var(--twc-neutral-600-opacity, var(--tw-border-opacity)));
}

.hover\:border-neutral-300:hover{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-neutral-300) / var(--twc-neutral-300-opacity, var(--tw-border-opacity)));
}

.hover\:border-primary_v2-700:hover{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-primary_v2-700) / var(--twc-primary_v2-700-opacity, var(--tw-border-opacity)));
}

.hover\:border-primary-600:hover{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-primary-600) / var(--twc-primary-600-opacity, var(--tw-border-opacity)));
}

.hover\:bg-neutral-70:hover{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-70) / var(--twc-neutral-70-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-primary-50:hover{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-50) / var(--twc-primary-50-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-asparagus-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(98, 124, 52, var(--tw-bg-opacity));
}

.hover\:bg-navy-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(1, 95, 163, var(--tw-bg-opacity));
}

.hover\:bg-neutral-50:hover{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-50) / var(--twc-neutral-50-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-transparent:hover{
    background-color: transparent;
}

.hover\:bg-primary-700:hover{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-700) / var(--twc-primary-700-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-neutral-600:hover{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-600) / var(--twc-neutral-600-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-neutral-300:hover{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-300) / var(--twc-neutral-300-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-primary_v2-700:hover{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary_v2-700) / var(--twc-primary_v2-700-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-primary-100:hover{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-100) / var(--twc-primary-100-opacity, var(--tw-bg-opacity)));
}

.hover\:text-neutral-200:hover{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-200) / var(--twc-neutral-200-opacity, var(--tw-text-opacity)));
}

.hover\:text-primary-500:hover{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-primary-500) / var(--twc-primary-500-opacity, var(--tw-text-opacity)));
}

.hover\:underline:hover{
    text-decoration: underline;
}

.hover\:duration-200:hover{
    transition-duration: 200ms;
}

.focus\:border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.focus\:border-info-500:focus{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-info-500) / var(--twc-info-500-opacity, var(--tw-border-opacity)));
}

.focus\:border-b-primary-500:focus{
    --tw-border-opacity: 1;
    border-bottom-color: hsl(var(--twc-primary-500) / var(--twc-primary-500-opacity, var(--tw-border-opacity)));
}

.focus\:bg-neutral-600:focus{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-600) / var(--twc-neutral-600-opacity, var(--tw-bg-opacity)));
}

.focus\:bg-neutral-300:focus{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-300) / var(--twc-neutral-300-opacity, var(--tw-bg-opacity)));
}

.focus\:bg-primary_v2-700:focus{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary_v2-700) / var(--twc-primary_v2-700-opacity, var(--tw-bg-opacity)));
}

.focus\:bg-primary-700:focus{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-700) / var(--twc-primary-700-opacity, var(--tw-bg-opacity)));
}

.focus\:bg-opacity-25:focus{
    --tw-bg-opacity: 0.25;
}

.focus\:text-primary-500:focus{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-primary-500) / var(--twc-primary-500-opacity, var(--tw-text-opacity)));
}

.focus\:outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}

.focus\:ring-error-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: hsl(var(--twc-error-100) / var(--twc-error-100-opacity, var(--tw-ring-opacity)));
}

.focus\:ring-success-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: hsl(var(--twc-success-100) / var(--twc-success-100-opacity, var(--tw-ring-opacity)));
}

.focus\:ring-info-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: hsl(var(--twc-info-500) / var(--twc-info-500-opacity, var(--tw-ring-opacity)));
}

.focus\:ring-primary-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: hsl(var(--twc-primary-100) / var(--twc-primary-100-opacity, var(--tw-ring-opacity)));
}

.focus\:ring-offset-1:focus{
    --tw-ring-offset-width: 1px;
}

.active\:border-primary-500:active{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-primary-500) / var(--twc-primary-500-opacity, var(--tw-border-opacity)));
}

.active\:bg-neutral-200:active{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-200) / var(--twc-neutral-200-opacity, var(--tw-bg-opacity)));
}

.active\:bg-primary-100:active{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-100) / var(--twc-primary-100-opacity, var(--tw-bg-opacity)));
}

.active\:bg-neutral-100:active{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-100) / var(--twc-neutral-100-opacity, var(--tw-bg-opacity)));
}

.active\:text-primary-500:active{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-primary-500) / var(--twc-primary-500-opacity, var(--tw-text-opacity)));
}

.disabled\:cursor-not-allowed:disabled{
    cursor: not-allowed;
}

.disabled\:border-neutral-100:disabled{
    --tw-border-opacity: 1;
    border-color: hsl(var(--twc-neutral-100) / var(--twc-neutral-100-opacity, var(--tw-border-opacity)));
}

.disabled\:border-gray-200:disabled{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.disabled\:bg-asparagus-600:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(98, 124, 52, var(--tw-bg-opacity));
}

.disabled\:bg-navy-blue-700:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(1, 95, 163, var(--tw-bg-opacity));
}

.disabled\:bg-gray-50:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.disabled\:bg-neutral-100:disabled{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-100) / var(--twc-neutral-100-opacity, var(--tw-bg-opacity)));
}

.disabled\:bg-gray-100:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.disabled\:bg-neutral-50:disabled{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-neutral-50) / var(--twc-neutral-50-opacity, var(--tw-bg-opacity)));
}

.disabled\:text-gray-300:disabled{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.disabled\:text-neutral-300:disabled{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-300) / var(--twc-neutral-300-opacity, var(--tw-text-opacity)));
}

.disabled\:text-neutral-200:disabled{
    --tw-text-opacity: 1;
    color: hsl(var(--twc-neutral-200) / var(--twc-neutral-200-opacity, var(--tw-text-opacity)));
}

.disabled\:text-white:disabled{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.disabled\:hover\:bg-white:disabled:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.disabled\:focus\:bg-white:disabled:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.disabled\:active\:bg-white:disabled:active{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:translate-x-full{
    --tw-translate-x: 100%;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}

.peer:checked ~ .peer-checked\:bg-primary-600{
    --tw-bg-opacity: 1;
    background-color: hsl(var(--twc-primary-600) / var(--twc-primary-600-opacity, var(--tw-bg-opacity)));
}

.peer:checked ~ .peer-checked\:text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@-webkit-keyframes collapsible-down{

    from{
        height: 0;
    }

    to{
        height: var(--radix-collapsible-content-height);
    }
}

@keyframes collapsible-down{

    from{
        height: 0;
    }

    to{
        height: var(--radix-collapsible-content-height);
    }
}

[data-state="open"] .data-open\:animate-collapsible-down, [data-state="open"].data-open\:animate-collapsible-down{
    -webkit-animation: collapsible-down 0.2s ease-out;
            animation: collapsible-down 0.2s ease-out;
}

@-webkit-keyframes slide-in-from-left{

    0%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    100%{
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes slide-in-from-left{

    0%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    100%{
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

[data-state="open"] .data-open\:animate-slide-in-from-left, [data-state="open"].data-open\:animate-slide-in-from-left{
    -webkit-animation: slide-in-from-left 0.3s ease-out forwards;
            animation: slide-in-from-left 0.3s ease-out forwards;
}

[data-state="open"] .data-open\:bg-opacity-80, [data-state="open"].data-open\:bg-opacity-80{
    --tw-bg-opacity: 0.8;
}

[data-state="open"] .data-open\:duration-500, [data-state="open"].data-open\:duration-500{
    transition-duration: 500ms;
}

[data-state="closed"] .data-closed\:rotate-0, [data-state="closed"].data-closed\:rotate-0{
    --tw-rotate: 0deg;
    -webkit-transform: var(--tw-transform);
            transform: var(--tw-transform);
}

@-webkit-keyframes collapsible-up{

    from{
        height: var(--radix-collapsible-content-height);
    }

    to{
        height: 0;
    }
}

@keyframes collapsible-up{

    from{
        height: var(--radix-collapsible-content-height);
    }

    to{
        height: 0;
    }
}

[data-state="closed"] .data-closed\:animate-collapsible-up, [data-state="closed"].data-closed\:animate-collapsible-up{
    -webkit-animation: collapsible-up 0.2s ease-out;
            animation: collapsible-up 0.2s ease-out;
}

@-webkit-keyframes slide-out-to-left{

    0%{
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}

@keyframes slide-out-to-left{

    0%{
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}

[data-state="closed"] .data-closed\:animate-slide-out-to-left, [data-state="closed"].data-closed\:animate-slide-out-to-left{
    -webkit-animation: slide-out-to-left 0.2s ease-in forwards;
            animation: slide-out-to-left 0.2s ease-in forwards;
}

[data-state="closed"] .data-closed\:bg-opacity-0, [data-state="closed"].data-closed\:bg-opacity-0{
    --tw-bg-opacity: 0;
}

[data-state="closed"] .data-closed\:duration-300, [data-state="closed"].data-closed\:duration-300{
    transition-duration: 300ms;
}

@media (min-width: 640px){

    .sm\:mt-0{
        margin-top: 0px;
    }

    .sm\:mr-0{
        margin-right: 0px;
    }

    .sm\:w-full{
        width: 100%;
    }

    .sm\:max-w-sm{
        max-width: 24rem;
    }

    .sm\:flex-row-reverse{
        flex-direction: row-reverse;
    }

    .sm\:flex-col{
        flex-direction: column;
    }

    .sm\:justify-center{
        justify-content: center;
    }

    .sm\:p-0{
        padding: 0px;
    }

    .sm\:px-0{
        padding-left: 0px;
        padding-right: 0px;
    }

    .sm\:py-0{
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .sm\:ease-out{
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

@media (min-width: 768px){

    .md\:left-1\/2{
        left: 50%;
    }

    .md\:top-1\/2{
        top: 50%;
    }

    .md\:bottom-auto{
        bottom: auto;
    }

    .md\:top-4{
        top: 1rem;
    }

    .md\:right-24{
        right: 6rem;
    }

    .md\:block{
        display: block;
    }

    .md\:flex{
        display: flex;
    }

    .md\:hidden{
        display: none;
    }

    .md\:h-16{
        height: 4rem;
    }

    .md\:max-h-\[80vh\]{
        max-height: 80vh;
    }

    .md\:w-\[270px\]{
        width: 270px;
    }

    .md\:w-40{
        width: 10rem;
    }

    .md\:w-\[300px\]{
        width: 300px;
    }

    .md\:w-1\/2{
        width: 50%;
    }

    .md\:w-16{
        width: 4rem;
    }

    .md\:w-\[93\%\]{
        width: 93%;
    }

    .md\:max-w-\[30vw\]{
        max-width: 30vw;
    }

    .md\:max-w-md{
        max-width: 28rem;
    }

    .md\:-translate-x-1\/2{
        --tw-translate-x: -50%;
        -webkit-transform: var(--tw-transform);
                transform: var(--tw-transform);
    }

    .md\:-translate-y-1\/2{
        --tw-translate-y: -50%;
        -webkit-transform: var(--tw-transform);
                transform: var(--tw-transform);
    }

    .md\:flex-row-reverse{
        flex-direction: row-reverse;
    }

    .md\:flex-col{
        flex-direction: column;
    }

    .md\:justify-start{
        justify-content: flex-start;
    }

    .md\:rounded-lg{
        border-radius: 0.5rem;
    }

    .md\:border-0{
        border-width: 0px;
    }

    .md\:border-r{
        border-right-width: 1px;
    }

    .md\:border-solid{
        border-style: solid;
    }

    .md\:border-r-neutral-100{
        --tw-border-opacity: 1;
        border-right-color: hsl(var(--twc-neutral-100) / var(--twc-neutral-100-opacity, var(--tw-border-opacity)));
    }

    .md\:p-5{
        padding: 1.25rem;
    }

    .md\:p-4{
        padding: 1rem;
    }

    .md\:text-xs{
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
}

@media (min-width: 1024px){

    .lg\:static{
        position: static;
    }

    .lg\:sticky{
        position: -webkit-sticky;
        position: sticky;
    }

    .lg\:bottom-0{
        bottom: 0px;
    }

    .lg\:z-10{
        z-index: 10;
    }

    .lg\:mt-5{
        margin-top: 1.25rem;
    }

    .lg\:-mt-4{
        margin-top: -1rem;
    }

    .lg\:mt-0{
        margin-top: 0px;
    }

    .lg\:mb-10{
        margin-bottom: 2.5rem;
    }

    .lg\:mt-10{
        margin-top: 2.5rem;
    }

    .lg\:mb-5{
        margin-bottom: 1.25rem;
    }

    .lg\:mt-4{
        margin-top: 1rem;
    }

    .lg\:mb-0{
        margin-bottom: 0px;
    }

    .lg\:ml-4{
        margin-left: 1rem;
    }

    .lg\:mb-8{
        margin-bottom: 2rem;
    }

    .lg\:block{
        display: block;
    }

    .lg\:flex{
        display: flex;
    }

    .lg\:grid{
        display: grid;
    }

    .lg\:inline-grid{
        display: inline-grid;
    }

    .lg\:hidden{
        display: none;
    }

    .lg\:h-14{
        height: 3.5rem;
    }

    .lg\:h-full{
        height: 100%;
    }

    .lg\:h-\[90px\]{
        height: 90px;
    }

    .lg\:h-\[45vh\]{
        height: 45vh;
    }

    .lg\:min-h-dvh{
        min-height: 100dvh;
    }

    .lg\:w-\[40\%\]{
        width: 40%;
    }

    .lg\:w-64{
        width: 16rem;
    }

    .lg\:w-full{
        width: 100%;
    }

    .lg\:w-\[400px\]{
        width: 400px;
    }

    .lg\:w-8\/12{
        width: 66.666667%;
    }

    .lg\:w-4\/12{
        width: 33.333333%;
    }

    .lg\:w-1\/3{
        width: 33.333333%;
    }

    .lg\:w-auto{
        width: auto;
    }

    .lg\:w-\[50\%\]{
        width: 50%;
    }

    .lg\:w-\[35\%\]{
        width: 35%;
    }

    .lg\:w-\[70\%\]{
        width: 70%;
    }

    .lg\:w-1\/2{
        width: 50%;
    }

    .lg\:w-40{
        width: 10rem;
    }

    .lg\:w-\[442px\]{
        width: 442px;
    }

    .lg\:w-\[97\%\]{
        width: 97%;
    }

    .lg\:w-\[85\%\]{
        width: 85%;
    }

    .lg\:w-\[65\%\]{
        width: 65%;
    }

    .lg\:w-\[31\%\]{
        width: 31%;
    }

    .lg\:w-\[29\%\]{
        width: 29%;
    }

    .lg\:w-\[32\%\]{
        width: 32%;
    }

    .lg\:w-max{
        width: -webkit-max-content;
        width: max-content;
    }

    .lg\:max-w-\[60\%\]{
        max-width: 60%;
    }

    .lg\:max-w-\[50vw\]{
        max-width: 50vw;
    }

    .lg\:max-w-\[442px\]{
        max-width: 442px;
    }

    .lg\:max-w-\[546px\]{
        max-width: 546px;
    }

    .lg\:max-w-\[530px\]{
        max-width: 530px;
    }

    .lg\:max-w-\[550px\]{
        max-width: 550px;
    }

    .lg\:max-w-2xl{
        max-width: 42rem;
    }

    .lg\:flex-none{
        flex: none;
    }

    .lg\:flex-auto{
        flex: 1 1 auto;
    }

    .lg\:flex-grow{
        flex-grow: 1;
    }

    .lg\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lg\:flex-row{
        flex-direction: row;
    }

    .lg\:flex-wrap{
        flex-wrap: wrap;
    }

    .lg\:items-end{
        align-items: flex-end;
    }

    .lg\:justify-end{
        justify-content: flex-end;
    }

    .lg\:justify-around{
        justify-content: space-around;
    }

    .lg\:gap-11{
        gap: 2.75rem;
    }

    .lg\:gap-8{
        gap: 2rem;
    }

    .lg\:gap-4{
        gap: 1rem;
    }

    .lg\:gap-\[60px\]{
        gap: 60px;
    }

    .lg\:gap-x-0{
        -webkit-column-gap: 0px;
                column-gap: 0px;
    }

    .lg\:gap-y-12{
        row-gap: 3rem;
    }

    .lg\:rounded-lg{
        border-radius: 0.5rem;
    }

    .lg\:rounded{
        border-radius: 0.25rem;
    }

    .lg\:border{
        border-width: 1px;
    }

    .lg\:border-0{
        border-width: 0px;
    }

    .lg\:border-t-0{
        border-top-width: 0px;
    }

    .lg\:border-r-0{
        border-right-width: 0px;
    }

    .lg\:border-l-0{
        border-left-width: 0px;
    }

    .lg\:border-b-0{
        border-bottom-width: 0px;
    }

    .lg\:border-l{
        border-left-width: 1px;
    }

    .lg\:border-solid{
        border-style: solid;
    }

    .lg\:border-gray-300{
        --tw-border-opacity: 1;
        border-color: rgba(209, 213, 219, var(--tw-border-opacity));
    }

    .lg\:border-l-neutral-100{
        --tw-border-opacity: 1;
        border-left-color: hsl(var(--twc-neutral-100) / var(--twc-neutral-100-opacity, var(--tw-border-opacity)));
    }

    .lg\:bg-neutral-0{
        --tw-bg-opacity: 1;
        background-color: hsl(var(--twc-neutral-0) / var(--twc-neutral-0-opacity, var(--tw-bg-opacity)));
    }

    .lg\:bg-neutral-50{
        --tw-bg-opacity: 1;
        background-color: hsl(var(--twc-neutral-50) / var(--twc-neutral-50-opacity, var(--tw-bg-opacity)));
    }

    .lg\:p-0{
        padding: 0px;
    }

    .lg\:p-4{
        padding: 1rem;
    }

    .lg\:p-2{
        padding: 0.5rem;
    }

    .lg\:p-10{
        padding: 2.5rem;
    }

    .lg\:py-4{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .lg\:py-6{
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .lg\:px-5{
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .lg\:px-3{
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

    .lg\:px-7{
        padding-left: 1.75rem;
        padding-right: 1.75rem;
    }

    .lg\:px-4{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .lg\:py-10{
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .lg\:py-0{
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .lg\:px-0{
        padding-left: 0px;
        padding-right: 0px;
    }

    .lg\:px-2{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .lg\:px-6{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .lg\:pt-8{
        padding-top: 2rem;
    }

    .lg\:pl-16{
        padding-left: 4rem;
    }

    .lg\:pr-8{
        padding-right: 2rem;
    }

    .lg\:pr-10{
        padding-right: 2.5rem;
    }

    .lg\:pb-14{
        padding-bottom: 3.5rem;
    }

    .lg\:pt-10{
        padding-top: 2.5rem;
    }

    .lg\:pb-4{
        padding-bottom: 1rem;
    }

    .lg\:pb-2\.5{
        padding-bottom: 0.625rem;
    }

    .lg\:pb-2{
        padding-bottom: 0.5rem;
    }

    .lg\:text-md{
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .lg\:text-base{
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .lg\:text-sm{
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .lg\:font-semibold{
        font-weight: 600;
    }
}

@media (min-width: 1280px){

    .xl\:grid-cols-3{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .xl\:px-0{
        padding-left: 0px;
        padding-right: 0px;
    }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* Address Finder */
.address-autocomplete {
  position: relative !important;
  width: 100%;
}

.address-autocomplete__suggestions {
  padding: 0;
  background: white;
  border-radius: 3px;
  z-index: 1001;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
  list-style: none;
  width: 100%;
}

.address-autocomplete__suggestions__item {
  font-size: 1rem;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  text-overflow: ellipsis;
}

.address-autocomplete__suggestions__item--active {
  background: #eef0f2;
  font-weight: bold;
}

/* Routing Animation - FADE */
.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit {
  transition: opacity 0.2s linear;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

/* //////////////////////////////////// */

/* Routing Animation - SLIDE-LEFT */
.slide-left-appear,
.slide-left-enter {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
}

.slide-left-appear-active,
.slide-left-enter-active {
  opacity: 1;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: opacity 1000ms, -webkit-transform ease-out 1000ms;
  transition: opacity 1000ms, transform ease-out 1000ms;
  transition: opacity 1000ms, transform ease-out 1000ms, -webkit-transform ease-out 1000ms;
}

.slide-left-exit {
  opacity: 1;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.slide-left-exit-active {
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: opacity 1000ms, -webkit-transform ease-out 1000ms;
  transition: opacity 1000ms, transform ease-out 1000ms;
  transition: opacity 1000ms, transform ease-out 1000ms, -webkit-transform ease-out 1000ms;
}

/* //////////////////////////////////// */

/* Routing Animation - SLIDE-RIGHT */
.slide-right-appear,
.slide-right-enter {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
}

.slide-right-appear-active,
.slide-right-enter-active {
  opacity: 1;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: opacity 1000ms, -webkit-transform ease-out 1000ms;
  transition: opacity 1000ms, transform ease-out 1000ms;
  transition: opacity 1000ms, transform ease-out 1000ms, -webkit-transform ease-out 1000ms;
}

.slide-right-exit {
  opacity: 1;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.slide-right-exit-active {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: opacity 1000ms, -webkit-transform ease-out 1000ms;
  transition: opacity 1000ms, transform ease-out 1000ms;
  transition: opacity 1000ms, transform ease-out 1000ms, -webkit-transform ease-out 1000ms;
}

