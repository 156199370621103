/* Routing Animation - FADE */
.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit {
  transition: opacity 0.2s linear;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

/* //////////////////////////////////// */

/* Routing Animation - SLIDE-LEFT */
.slide-left-appear,
.slide-left-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-appear-active,
.slide-left-enter-active {
  opacity: 1;
  transform: translateX(-100%);
  transition: opacity 1000ms, transform ease-out 1000ms;
}

.slide-left-exit {
  opacity: 1;
  transform: translateX(-100%);
}

.slide-left-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1000ms, transform ease-out 1000ms;
}

/* //////////////////////////////////// */

/* Routing Animation - SLIDE-RIGHT */
.slide-right-appear,
.slide-right-enter {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-right-appear-active,
.slide-right-enter-active {
  opacity: 1;
  transform: translateX(100%);
  transition: opacity 1000ms, transform ease-out 1000ms;
}

.slide-right-exit {
  opacity: 1;
  transform: translateX(100%);
}

.slide-right-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1000ms, transform ease-out 1000ms;
}
