@import '@fontsource/manrope/400.css';
@import '@fontsource/manrope/500.css';
@import '@fontsource/manrope/600.css';
@import '@fontsource/manrope/700.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --tw-scroll-snap-strictness: mandatory;
  }

  * {
    @apply font-manrope;
  }

  html,
  body {
    min-height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    flex: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: auto;
    @apply font-manrope;
  }

  #root {
    height: 100%;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}

/* make modal overlap everything
  previously it was being overlapped by the toolbar
  and the open in app message bar
 */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f2f2f2;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c0cece;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c0cece;
}

/* handle last pass iframe covering the entire screen on mobile  */
@media screen and (max-width: 768px) {
  [data-lastpass-save] {
    display: none !important;
    pointer-events: none;
    z-index: -1;
  }
}

.box-border-all,
.box-border-all * {
  box-sizing: border-box;
}

/* to prevent background scroll and weird positioning when dialog is open */
html:has(dialog[open]:modal) {
  overflow: hidden;
}

@layer utilities {
  .snap-x {
    --tw-scroll-snap-strictness: proximity;
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
  }

  .snap-mandatory {
    --tw-scroll-snap-strictness: mandatory;
  }
  .snap-center {
    scroll-snap-align: center;
  }

  @media screen {
    /* For Webkit-based browsers (Chrome, Safari and Opera) */
    .scrollbar-hidden::-webkit-scrollbar {
      display: none;
    }

    /* For IE, Edge and Firefox */
    .scrollbar-hidden {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

@layer components {
  .w-main-container {
    @apply w-full lg:max-w-2xl;
  }
  .w-main-container-lg {
    @apply w-full lg:max-w-5xl;
  }

  dialog::backdrop {
    @apply bg-neutral-500 bg-opacity-60;
  }

  /* to prevent background scroll and weird positioning when dialog is open */
  html:has(dialog[open]:modal) {
    @apply overflow-hidden;
  }
}

/* handle last pass iframe covering the entire screen on mobile  */
@media screen and (max-width: 768px) {
  [data-lastpass-save] {
    display: none !important;
    pointer-events: none;
    z-index: -1;
  }
}

/* only applied on ipad with retina display */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 720px) and (max-width: 1024px),
  only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 720px) and (max-width: 1024px),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 720px) and (max-width: 1024px),
  only screen and (min-device-pixel-ratio: 2) and (min-width: 720px) and (max-width: 1024px),
  only screen and (min-resolution: 192dpi) and (min-width: 720px) and (max-width: 1024px),
  only screen and (min-resolution: 2dppx) and (min-width: 720px) and (max-width: 1024px) {
  .\!ipad\:max-w-full {
    max-width: 100% !important;
  }
}

a {
  @apply text-brand-500 underline cursor-pointer;
}

@layer utilities {
  .will-change-width {
    will-change: width;
  }
  .will-change-transform {
    will-change: transform;
  }
  .bg-inherit {
    background-color: inherit;
  }
}

@layer base {
  .ignore-tw-style-reset ul,
  .ignore-tw-style-reset ol {
    list-style: revert;
    margin: revert;
    padding: revert;
  }
}
