.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* Address Finder */
.address-autocomplete {
  position: relative !important;
  width: 100%;
}

.address-autocomplete__suggestions {
  padding: 0;
  background: white;
  border-radius: 3px;
  z-index: 1001;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
  list-style: none;
  width: 100%;
}

.address-autocomplete__suggestions__item {
  font-size: 1rem;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  text-overflow: ellipsis;
}

.address-autocomplete__suggestions__item--active {
  background: #eef0f2;
  font-weight: bold;
}
